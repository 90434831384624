import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class AdmissionProcessService {

      #api;
      constructor() {
            this.#api = `${API_URL}user/admission-process`;
      }

      paginate(page = 1) {
            let url = `${this.#api}`;
            if (page)
                  url = url + '?page=' + page;
            return apiService.get(url);
      }

      getByProgram(program) {
            let url = `${this.#api}/${program}/by-program`;
            return apiService.get(url);
      }

      all() {
            let url = `${this.#api}-all`;
            return apiService.get(url);
      }

      update(id, data) {
            let url = `${this.#api}/${id}`
            return apiService.put(url, data);

      }

      store(data) {
            let url = `${this.#api}`
            return apiService.post(url, data);

      }

      show(id) {
            let url = `${this.#api}/${id}`
            return apiService.get(url);
      }

      delete(id) {
            let url = `${this.#api}/${id}`
            return apiService.delete(url);
      }
}
