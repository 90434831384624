<template>
  <div class="card card-custom">
    <div class="card-body p-0">
      <div
        class="wizard wizard-2"
        id="kt_wizard_v2"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
          <div class="row">
            <div class="col-sm-12 col-lg-6 col-md-6">
              <h4>
               Manage Admission Process for {{program}}
              </h4>
            </div>
            <div class="col-sm-12 col-lg-6 col-md-6 text-right">
              <router-link :to="{name:'admission-process-new', params:{program:program}}" class="btn btn-primary ml-2">

                Add
              </router-link>
            </div>
            <div class="col-12">
              <table class="table table-stripe">
                <thead>
                  <th>Title</th>
                  <th>Active</th>
                  <th>Action</th>
                </thead>
                <tbody>
                  <tr v-for="(admission, index) of admissions" :key="index">
                    <td>{{admission.title}}</td>

                    <td>
                      <i
                        class="fas fa-circle"
                        :class="admission.is_active?'text-success':'text-danger'"
                      ></i>
                    </td>
                    <td>
                      <span class="cursor-pointer" @click="editAdmissionProcess(admission.id)">
                        <i class="fas fa-edit"></i>
                      </span>
                      <span
                        class="ml-2 cursor-pointer"
                        @click="deleteAdmissionproces(admission.id)"
                      >
                        <i class="fas fa-trash"></i>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AdmissionProcessService from "@/core/services/level/program/admission-process/AdmissionProcessService";

const admissionProcess = new AdmissionProcessService();

export default {
  name: "admission-Process",
  components: {},
  data() {
    return {
      admissions: []
    };
  },
  computed: {
    program() {
      return this.$route.params.program;
    }
  },
  mounted() {
    this.getAdmissionprocess();
  },
  methods: {
    getAdmissionprocess() {
      admissionProcess.getByProgram(this.program).then(response => {
        this.admissions = response.data;
      });
    },
    editAdmissionProcess(id) {
      this.$router.push({
        name: "admission-process-update",
        params: { program:this.program, process_id: id }
      });
    },

    deleteAdmissionproces(id) {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            admissionProcess
              .delete(id)
              .then(response => {
                this.$snotify.success("Information deleted");

                this.getAdmissionprocess();
              })
              .catch(error => {
                //console.log(error);
              });
          }
        }
      });
    }
  }
};
</script>
